@font-face {
  font-family: Avenir Next Condensed;
  src: local(Avenir Next Condensed Bold), local(AvenirNextCondensed-Bold), url("AvenirNextCondensed-Bold.57dc2cba.woff2") format("woff2"), url("AvenirNextCondensed-Bold.5a72b9b7.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Avenir Next Condensed;
  src: local(Avenir Next Condensed Medium), local(AvenirNextCondensed-Medium), url("AvenirNextCondensed-Medium.03becb51.woff2") format("woff2"), url("AvenirNextCondensed-Medium.737b59f9.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Avenir Next Condensed;
  src: local(Avenir Next Condensed Regular), local(AvenirNextCondensed-Regular), url("AvenirNextCondensed-Regular.17ce6db3.woff2") format("woff2"), url("AvenirNextCondensed-Regular.6681daab.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Avenir Next Condensed;
  src: local(Avenir Next Condensed Heavy), local(AvenirNextCondensed-Heavy), url("AvenirNextCondensed-Heavy.e75aede6.woff2") format("woff2"), url("AvenirNextCondensed-Heavy.4c565b34.woff") format("woff");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

:root {
  --background: #000;
  --restrict-container: 1200px;
  --primary: #fde36c;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: Avenir Next Condensed, san-serif;
  font-weight: 900;
}

a {
  cursor: pointer;
}

a:hover {
  color: var(--primary);
}

html {
  background: var(--background);
  color: #fff;
  font-size: calc(60% + .8vmin);
}

html, body {
  background: url("left-clouds.a6a8cc15.png") 0 100% / 600px no-repeat, url("right-clouds.181242d2.png") 100% 100% / 800px no-repeat, url("sky-pattern.549bfef0.png") 0 0 / 8px 8px;
  width: 100%;
}

main {
  width: inherit;
  height: inherit;
  align-items: center;
  display: flex;
}

header {
  padding-top: 2rem;
}

header ul {
  justify-content: center;
  gap: .75rem;
  display: flex;
}

header ul li {
  list-style: none;
}

header ul li a {
  color: #fff;
  text-transform: uppercase;
  font-size: 1.5rem;
  text-decoration: none;
}

.enter {
  text-transform: uppercase;
  color: #fff;
  cursor: pointer;
  background: none;
  margin: .5rem 0 5rem;
  padding: .5rem 3rem;
  font-size: 2rem;
  text-decoration: none;
  display: inline-block;
}

.enter:hover {
  color: var(--primary);
}

.enter[disabled] {
  opacity: .5;
  pointer-events: none;
}
/*# sourceMappingURL=index.7630a18f.css.map */

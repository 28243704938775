@font-face {
  font-family: 'Avenir Next Condensed';
  src: local('Avenir Next Condensed Bold'), local('AvenirNextCondensed-Bold'),
      url('./fonts/AvenirNextCondensed-Bold.woff2') format('woff2'),
      url('./fonts/AvenirNextCondensed-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Avenir Next Condensed';
  src: local('Avenir Next Condensed Medium'), local('AvenirNextCondensed-Medium'),
      url('./fonts/AvenirNextCondensed-Medium.woff2') format('woff2'),
      url('./fonts/AvenirNextCondensed-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Avenir Next Condensed';
  src: local('Avenir Next Condensed Regular'), local('AvenirNextCondensed-Regular'),
      url('./fonts/AvenirNextCondensed-Regular.woff2') format('woff2'),
      url('./fonts/AvenirNextCondensed-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}



@font-face {
  font-family: 'Avenir Next Condensed';
  src: local('Avenir Next Condensed Heavy'), local('AvenirNextCondensed-Heavy'),
      url('./fonts/AvenirNextCondensed-Heavy.woff2') format('woff2'),
      url('./fonts/AvenirNextCondensed-Heavy.woff') format('woff');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}


:root {
  --background: #000000;
  --restrict-container: 1200px;
  --primary: #fde36c;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Avenir Next Condensed", san-serif;
  font-weight: 900;
}

a {
  cursor: pointer;
  &:hover {
    color: var(--primary);
  }
}

html {
  background: var(--background);
  color: white;
  font-size: calc(60% + 0.8vmin);
}

html, body {
  width: 100%;
  background: url('./img/left-clouds.png') no-repeat, url('./img/right-clouds.png') no-repeat, url('./img/sky-pattern.png') repeat;
  /* background-color: #009dff; */
  background-position: bottom left, bottom right, top left;
  background-size: 600px auto, 800px auto, 8px 8px;
}

main {
  width: inherit;
  height: inherit;
  display: flex;
  align-items: center;
}

header{
  padding-top: 2rem;
  ul {
    display: flex;
    justify-content: center;
    gap: 0.75rem;

    li {
      list-style: none;
      a {
        font-size: 1.5rem;
        color: white;
        text-decoration: none;
        text-transform: uppercase;
      }
    }
  }
}

.enter {
  font-size: 2rem;
  text-transform: uppercase;
  padding: 0.5rem 3rem;
  // border: 2px solid white;
  background: none;
  color: white;
  margin: 0.5rem 0 5rem;
  text-decoration: none;
  display: inline-block;
  cursor: pointer;

  &:hover {
    color: var(--primary);
  }

  &[disabled] {
    opacity: 0.5;
    pointer-events: none;
  }
}